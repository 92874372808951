/**
 * easygreentracking-api
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.1-rc.0.412+e1a3423
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface CapabilityTypeDto { 
    id: number;
    capability: CapabilityTypeDto.CapabilityEnum;
}
export namespace CapabilityTypeDto {
    export type CapabilityEnum = 'BASIC_CAPABILITIES' | 'START_CONSIGNEE_CYCLE' | 'END_CONSIGNEE_CYCLE' | 'START_CONSIGNEE_CYCLE_TRANSFERSHIPMENT' | 'START_CONSIGNEE_CYCLE_RETURNSHIPMENT' | 'SERVICE_CENTER_SCAN' | 'ALLOW_SHIPMENT_CREATION_WITHOUT_VALIDATION';
    export const CapabilityEnum = {
        BASICCAPABILITIES: 'BASIC_CAPABILITIES' as CapabilityEnum,
        STARTCONSIGNEECYCLE: 'START_CONSIGNEE_CYCLE' as CapabilityEnum,
        ENDCONSIGNEECYCLE: 'END_CONSIGNEE_CYCLE' as CapabilityEnum,
        STARTCONSIGNEECYCLETRANSFERSHIPMENT: 'START_CONSIGNEE_CYCLE_TRANSFERSHIPMENT' as CapabilityEnum,
        STARTCONSIGNEECYCLERETURNSHIPMENT: 'START_CONSIGNEE_CYCLE_RETURNSHIPMENT' as CapabilityEnum,
        SERVICECENTERSCAN: 'SERVICE_CENTER_SCAN' as CapabilityEnum,
        ALLOWSHIPMENTCREATIONWITHOUTVALIDATION: 'ALLOW_SHIPMENT_CREATION_WITHOUT_VALIDATION' as CapabilityEnum
    };
}