/**
 * easygreentracking-api
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.1-rc.0.412+e1a3423
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface ErrorResponse { 
    errorCode: ErrorResponse.ErrorCodeEnum;
}
export namespace ErrorResponse {
    export type ErrorCodeEnum = 'UNKNOWN' | 'CAPSULE_NOT_FOUND' | 'SHIPMENT_NOT_FOUND' | 'SHIPMENT_ALREADY_EXISTS' | 'STOCK_NOT_FOUND' | 'PASSWORD_REQUIREMENTS_NOT_MET' | 'PASSWORD_INCORRECT' | 'STOCK_ALREADY_EXISTS' | 'FACILITY_NOT_FOUND' | 'ASSET_NOT_FOUND' | 'ASSET_ALREADY_IN_USE';
    export const ErrorCodeEnum = {
        UNKNOWN: 'UNKNOWN' as ErrorCodeEnum,
        CAPSULENOTFOUND: 'CAPSULE_NOT_FOUND' as ErrorCodeEnum,
        SHIPMENTNOTFOUND: 'SHIPMENT_NOT_FOUND' as ErrorCodeEnum,
        SHIPMENTALREADYEXISTS: 'SHIPMENT_ALREADY_EXISTS' as ErrorCodeEnum,
        STOCKNOTFOUND: 'STOCK_NOT_FOUND' as ErrorCodeEnum,
        PASSWORDREQUIREMENTSNOTMET: 'PASSWORD_REQUIREMENTS_NOT_MET' as ErrorCodeEnum,
        PASSWORDINCORRECT: 'PASSWORD_INCORRECT' as ErrorCodeEnum,
        STOCKALREADYEXISTS: 'STOCK_ALREADY_EXISTS' as ErrorCodeEnum,
        FACILITYNOTFOUND: 'FACILITY_NOT_FOUND' as ErrorCodeEnum,
        ASSETNOTFOUND: 'ASSET_NOT_FOUND' as ErrorCodeEnum,
        ASSETALREADYINUSE: 'ASSET_ALREADY_IN_USE' as ErrorCodeEnum
    };
}