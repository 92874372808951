/**
 * easygreentracking-api
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.1-rc.0.412+e1a3423
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { CapabilityTypeDto } from './capabilityTypeDto';
import { FacilityDetailShipmentOverviewDto } from './facilityDetailShipmentOverviewDto';

export interface FacilityDetailDto { 
    id: number;
    dhlFacilityId: string;
    type: FacilityDetailDto.TypeEnum;
    serviceAreaCode: string;
    name: string;
    countryCode: string;
    created: Date;
    zoneName: string;
    companyName: string;
    facilityStatus: FacilityDetailDto.FacilityStatusEnum;
    servicePointType: FacilityDetailDto.ServicePointTypeEnum;
    routeCode: string;
    routeCycleCode: string;
    regionCodeName: string;
    city: string;
    zipCode: string;
    address: string;
    accountNumber: string;
    capabilities: Array<CapabilityTypeDto>;
    currentShipments: Array<FacilityDetailShipmentOverviewDto>;
    shipmentCountToday: number;
}
export namespace FacilityDetailDto {
    export type TypeEnum = 'F' | 'P' | 'C' | 'EC';
    export const TypeEnum = {
        F: 'F' as TypeEnum,
        P: 'P' as TypeEnum,
        C: 'C' as TypeEnum,
        EC: 'EC' as TypeEnum
    };
    export type FacilityStatusEnum = 'A' | 'S' | 'X' | 'Y';
    export const FacilityStatusEnum = {
        A: 'A' as FacilityStatusEnum,
        S: 'S' as FacilityStatusEnum,
        X: 'X' as FacilityStatusEnum,
        Y: 'Y' as FacilityStatusEnum
    };
    export type ServicePointTypeEnum = 'PRT';
    export const ServicePointTypeEnum = {
        PRT: 'PRT' as ServicePointTypeEnum
    };
}