/**
 * easygreentracking-api
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.1-rc.0.412+e1a3423
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { CreateShipmentDto } from '../model/createShipmentDto';
import { CreateTransferShipmentDto } from '../model/createTransferShipmentDto';
import { ShipmentDto } from '../model/shipmentDto';
import { TransferShipmentDto } from '../model/transferShipmentDto';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class ShipmentApiService {

    protected basePath = '/';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * Cancel the shipment for the passed capsule
     * 
     * @param shipmentId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public cancelShipment(shipmentId: string, observe?: 'body', reportProgress?: boolean): Observable<ShipmentDto>;
    public cancelShipment(shipmentId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ShipmentDto>>;
    public cancelShipment(shipmentId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ShipmentDto>>;
    public cancelShipment(shipmentId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (shipmentId === null || shipmentId === undefined) {
            throw new Error('Required parameter shipmentId was null or undefined when calling cancelShipment.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<ShipmentDto>('post',`${this.basePath}/api/shipment/cancel/${encodeURIComponent(String(shipmentId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Create a shipment
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createShipment(body?: CreateShipmentDto, observe?: 'body', reportProgress?: boolean): Observable<ShipmentDto>;
    public createShipment(body?: CreateShipmentDto, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ShipmentDto>>;
    public createShipment(body?: CreateShipmentDto, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ShipmentDto>>;
    public createShipment(body?: CreateShipmentDto, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<ShipmentDto>('post',`${this.basePath}/api/shipment`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Create a shipment
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createTransferShipment(body?: CreateTransferShipmentDto, observe?: 'body', reportProgress?: boolean): Observable<TransferShipmentDto>;
    public createTransferShipment(body?: CreateTransferShipmentDto, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<TransferShipmentDto>>;
    public createTransferShipment(body?: CreateTransferShipmentDto, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<TransferShipmentDto>>;
    public createTransferShipment(body?: CreateTransferShipmentDto, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<TransferShipmentDto>('post',`${this.basePath}/api/shipment/transfer`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Returns all shipments
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAllShipments(observe?: 'body', reportProgress?: boolean): Observable<Array<ShipmentDto>>;
    public getAllShipments(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<ShipmentDto>>>;
    public getAllShipments(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<ShipmentDto>>>;
    public getAllShipments(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<ShipmentDto>>('get',`${this.basePath}/api/shipment/all`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Returns all Transfer shipments
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAllTransferShipments(observe?: 'body', reportProgress?: boolean): Observable<Array<TransferShipmentDto>>;
    public getAllTransferShipments(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<TransferShipmentDto>>>;
    public getAllTransferShipments(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<TransferShipmentDto>>>;
    public getAllTransferShipments(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<TransferShipmentDto>>('get',`${this.basePath}/api/shipment/transfer/all`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Returns all shipments of specified facility
     * 
     * @param facilityId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getShipments(facilityId: number, observe?: 'body', reportProgress?: boolean): Observable<Array<ShipmentDto>>;
    public getShipments(facilityId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<ShipmentDto>>>;
    public getShipments(facilityId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<ShipmentDto>>>;
    public getShipments(facilityId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (facilityId === null || facilityId === undefined) {
            throw new Error('Required parameter facilityId was null or undefined when calling getShipments.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (facilityId !== undefined && facilityId !== null) {
            queryParameters = queryParameters.set('facilityId', <any>facilityId);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<ShipmentDto>>('get',`${this.basePath}/api/shipment`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Returns all Transfer shipments
     * 
     * @param facilityId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getTransferShipments(facilityId: number, observe?: 'body', reportProgress?: boolean): Observable<Array<TransferShipmentDto>>;
    public getTransferShipments(facilityId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<TransferShipmentDto>>>;
    public getTransferShipments(facilityId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<TransferShipmentDto>>>;
    public getTransferShipments(facilityId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (facilityId === null || facilityId === undefined) {
            throw new Error('Required parameter facilityId was null or undefined when calling getTransferShipments.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (facilityId !== undefined && facilityId !== null) {
            queryParameters = queryParameters.set('facilityId', <any>facilityId);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<TransferShipmentDto>>('get',`${this.basePath}/api/shipment/transfer/`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
