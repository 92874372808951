/**
 * easygreentracking-api
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.1-rc.0.412+e1a3423
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { CapsuleTypeDto } from './capsuleTypeDto';
import { FacilityDto } from './facilityDto';

export interface CapsuleDetailDto { 
    capsuleId: number;
    dhlCapsuleId: string;
    statuses: Array<CapsuleDetailDto.StatusesEnum>;
    usages: number;
    facility: FacilityDto;
    lastKnownLocation: string;
    capsuleLocationStatus: CapsuleDetailDto.CapsuleLocationStatusEnum;
    capsuleType: CapsuleTypeDto;
    capsuleIotId: string;
    created: Date;
    shipment: boolean;
}
export namespace CapsuleDetailDto {
    export type StatusesEnum = 'MISSING' | 'CLEAN' | 'LOST' | 'BLE_LOST' | 'DIRTY' | 'BROKEN' | 'DECOMMISSIONED';
    export const StatusesEnum = {
        MISSING: 'MISSING' as StatusesEnum,
        CLEAN: 'CLEAN' as StatusesEnum,
        LOST: 'LOST' as StatusesEnum,
        BLELOST: 'BLE_LOST' as StatusesEnum,
        DIRTY: 'DIRTY' as StatusesEnum,
        BROKEN: 'BROKEN' as StatusesEnum,
        DECOMMISSIONED: 'DECOMMISSIONED' as StatusesEnum
    };
    export type CapsuleLocationStatusEnum = 'IN_STOCK' | 'LOST' | 'IN_DHL_SHIPMENT' | 'IN_EXTERNAL_SHIPMENT' | 'IN_TRANSFER_SHIPMENT' | 'UNKNOWN';
    export const CapsuleLocationStatusEnum = {
        INSTOCK: 'IN_STOCK' as CapsuleLocationStatusEnum,
        LOST: 'LOST' as CapsuleLocationStatusEnum,
        INDHLSHIPMENT: 'IN_DHL_SHIPMENT' as CapsuleLocationStatusEnum,
        INEXTERNALSHIPMENT: 'IN_EXTERNAL_SHIPMENT' as CapsuleLocationStatusEnum,
        INTRANSFERSHIPMENT: 'IN_TRANSFER_SHIPMENT' as CapsuleLocationStatusEnum,
        UNKNOWN: 'UNKNOWN' as CapsuleLocationStatusEnum
    };
}