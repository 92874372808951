/**
 * easygreentracking-api
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.1-rc.0.412+e1a3423
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { CapsuleCheckinDto } from '../model/capsuleCheckinDto';
import { CapsuleDetailDto } from '../model/capsuleDetailDto';
import { CapsuleDto } from '../model/capsuleDto';
import { CapsuleExportItem } from '../model/capsuleExportItem';
import { CreateCapsuleDto } from '../model/createCapsuleDto';
import { EditCapsuleDto } from '../model/editCapsuleDto';
import { EditCapsuleFacilityDto } from '../model/editCapsuleFacilityDto';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class CapsuleApiService {

    protected basePath = '/';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * Checkin capsule to facility
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public checkinCapsule(body?: CapsuleCheckinDto, observe?: 'body', reportProgress?: boolean): Observable<CapsuleDto>;
    public checkinCapsule(body?: CapsuleCheckinDto, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CapsuleDto>>;
    public checkinCapsule(body?: CapsuleCheckinDto, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CapsuleDto>>;
    public checkinCapsule(body?: CapsuleCheckinDto, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<CapsuleDto>('post',`${this.basePath}/api/capsule/checkin`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Creates a new capsule
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createCapsule(body?: CreateCapsuleDto, observe?: 'body', reportProgress?: boolean): Observable<CapsuleDto>;
    public createCapsule(body?: CreateCapsuleDto, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CapsuleDto>>;
    public createCapsule(body?: CreateCapsuleDto, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CapsuleDto>>;
    public createCapsule(body?: CreateCapsuleDto, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<CapsuleDto>('post',`${this.basePath}/api/capsule`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Edit capsule
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public editCapsule(body?: EditCapsuleDto, observe?: 'body', reportProgress?: boolean): Observable<CapsuleDto>;
    public editCapsule(body?: EditCapsuleDto, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CapsuleDto>>;
    public editCapsule(body?: EditCapsuleDto, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CapsuleDto>>;
    public editCapsule(body?: EditCapsuleDto, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<CapsuleDto>('put',`${this.basePath}/api/capsule`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Edit capsule facility
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public editCapsuleFacility(body?: EditCapsuleFacilityDto, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public editCapsuleFacility(body?: EditCapsuleFacilityDto, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public editCapsuleFacility(body?: EditCapsuleFacilityDto, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public editCapsuleFacility(body?: EditCapsuleFacilityDto, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('put',`${this.basePath}/api/capsule/facility`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Returns the requested capsule
     * 
     * @param dhlCapsuleId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getCapsule(dhlCapsuleId: string, observe?: 'body', reportProgress?: boolean): Observable<CapsuleDetailDto>;
    public getCapsule(dhlCapsuleId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CapsuleDetailDto>>;
    public getCapsule(dhlCapsuleId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CapsuleDetailDto>>;
    public getCapsule(dhlCapsuleId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (dhlCapsuleId === null || dhlCapsuleId === undefined) {
            throw new Error('Required parameter dhlCapsuleId was null or undefined when calling getCapsule.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<CapsuleDetailDto>('get',`${this.basePath}/api/capsule/${encodeURIComponent(String(dhlCapsuleId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Returns the History of a capsule
     * 
     * @param dhlCapsuleId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getCapsuleHistory(dhlCapsuleId: string, observe?: 'body', reportProgress?: boolean): Observable<Array<CapsuleExportItem>>;
    public getCapsuleHistory(dhlCapsuleId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<CapsuleExportItem>>>;
    public getCapsuleHistory(dhlCapsuleId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<CapsuleExportItem>>>;
    public getCapsuleHistory(dhlCapsuleId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (dhlCapsuleId === null || dhlCapsuleId === undefined) {
            throw new Error('Required parameter dhlCapsuleId was null or undefined when calling getCapsuleHistory.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<CapsuleExportItem>>('get',`${this.basePath}/api/capsule/${encodeURIComponent(String(dhlCapsuleId))}/history`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Returns all capsules of specified facility
     * 
     * @param facilityId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getCapsules(facilityId: number, observe?: 'body', reportProgress?: boolean): Observable<Array<CapsuleDto>>;
    public getCapsules(facilityId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<CapsuleDto>>>;
    public getCapsules(facilityId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<CapsuleDto>>>;
    public getCapsules(facilityId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (facilityId === null || facilityId === undefined) {
            throw new Error('Required parameter facilityId was null or undefined when calling getCapsules.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (facilityId !== undefined && facilityId !== null) {
            queryParameters = queryParameters.set('facilityId', <any>facilityId);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<CapsuleDto>>('get',`${this.basePath}/api/capsule`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Returns all capsules
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getCapsulesWithDetail(observe?: 'body', reportProgress?: boolean): Observable<Array<CapsuleDetailDto>>;
    public getCapsulesWithDetail(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<CapsuleDetailDto>>>;
    public getCapsulesWithDetail(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<CapsuleDetailDto>>>;
    public getCapsulesWithDetail(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<CapsuleDetailDto>>('get',`${this.basePath}/api/capsule/detail`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Unlinks a capsule by settings the IOT id to null
     * 
     * @param capsuleId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public unlinkCapsule(capsuleId: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public unlinkCapsule(capsuleId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public unlinkCapsule(capsuleId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public unlinkCapsule(capsuleId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (capsuleId === null || capsuleId === undefined) {
            throw new Error('Required parameter capsuleId was null or undefined when calling unlinkCapsule.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('post',`${this.basePath}/api/capsule/unlink/${encodeURIComponent(String(capsuleId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
